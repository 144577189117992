import {
  FindSelectorType,
  RequirementGroupType,
  RequirementType,
} from '@stafflink/zod-types/src/automations'

export type BaserowSelectOption = {
  id: number
  value: string
  color: string
}

export type BaserowCollaborator = {
  id: number
  name: string
  email: string
  user_id: string
}

export type SelectedLinkFieldRefineValueOption = {
  value: FindSelectorType
  label: string
}

export type BaserowObject = {
  inUseElsewhere?: boolean
  value: number
  label: string
  group?: string
  groupId?: number
}

export const setStringToTitleCase = (str: string) => {
  if (str) {
    const strings = str?.split('_')
    const newStrings = strings?.map((string) => {
      return string.charAt(0)?.toUpperCase() + string?.slice(1).toLowerCase()
    })
    return newStrings?.join(' ')
  }
  return str
}

export const selectColorConverter = (color: string) => {
  const colorTranslator = {
    'light-blue': '#f0f4fc',
    'light-green': '#ecfcf1',
    'light-cyan': '#cff5fa',
    'light-yellow': '#fffbf0',
    'light-orange': '#fffbf0',
    'light-red': '#fff2f0',
    'light-brown': '#f5e6dc',
    'light-purple': '#f9f1fd',
    'light-pink': '#f7e1f2',
    'light-gray': '#f5f5f5',
    blue: '#dae4fd',
    green: '#d0f6dc',
    cyan: '#a0ebf5',
    yellow: '#ffe9b4',
    orange: '#fff4da',
    red: '#ffdeda',
    brown: '#f5ceb0',
    purple: '#dfb9f7',
    pink: '#f7b2e7',
    gray: '#d7d8d9',
    'dark-blue': '#acc8f8',
    'dark-green': '#a0eeba',
    'dark-cyan': '#70e0ef',
    'dark-yellow': '#ffdd8f',
    'dark-orange': '#ffe9b4',
    'dark-red': '#ffbdb4',
    'dark-brown': '#f5c098',
    'dark-purple': '#cf96f2',
    'dark-pink': '#f285d9',
    'dark-gray': '#b5b5b7',
    'darker-blue': '#689ef1',
    'darker-green': '#41dd75',
    'darker-cyan': '#41d6ea',
    'darker-yellow': '#ffd269',
    'darker-orange': '#ffd269',
    'darker-red': '#ff7b69',
    'darker-brown': '#f5a96e',
    'darker-purple': '#bf73ee',
    'darker-pink': '#ff6dde',
    'darker-gray': '#b5b5b7',
  }

  return color in colorTranslator ? colorTranslator[color] : color
}

export const selectedLinkFieldRefineValueOptions: SelectedLinkFieldRefineValueOption[] =
  [
    {
      value: FindSelectorType.ALL_RECORDS,
      label: 'All Records',
    },
    {
      value: FindSelectorType.LAST_MODIFIED,
      label: 'Last Modified',
    },
    {
      value: FindSelectorType.LAST_CREATED,
      label: 'Last Created',
    },
    {
      value: FindSelectorType.FIRST_CREATED,
      label: 'First Created',
    },
    {
      value: FindSelectorType.FIRST_MODIFIED,
      label: 'First Modified',
    },
  ]

// Type guard to check if requirement is a RequirementGroupType
export const isRequirementGroupType = (
  requirement: RequirementType | RequirementGroupType,
): requirement is RequirementGroupType => {
  return 'requirements' in requirement
}
